jQuery(function ($) {
    $('input[type="date"]').prop("type","text");

    $(".dropdown-toggle").dropdown();

    $("#case-filter-stage").change(function(){
        $("#case-filter-form").submit()
    });

    $("#case-filter-category").change(function(){
        $("#case-filter-form").submit()
    });

    $("#case-detailed-checkbox").change(function(){
        $("#case-detailed-form").submit()
    });

    $("#stage-action-target").change(function(){
        if($(this).val()=="")
            $("#stage-action-target-custom").css("display","block");
        else{$("#stage-action-target-input").val("");
            $("#stage-action-target-custom").css("display","none");
        }
    });

    $("#modalCV").modal("show");

    $('.item-has-children').children('a').on('click', function(event){
        event.preventDefault();
        $(this).toggleClass('submenu-open').next('.sub-menu').slideToggle(200).end().parent('.item-has-children').siblings('.item-has-children').children('a').removeClass('submenu-open').next('.sub-menu').slideUp(200);
    });

    $(".hamburger-slim").click(function() {
        $(this).toggleClass("hamburger-toggle");
        $("body").toggleClass("off-canvas");
    });

    setTimeout(function() {
        $(".login-form form").addClass("animate");
    }, 1500);

    var immediateField=$("#field-immediate");
    immediateField.click(function(){
        $("#field-schedule_count").attr("disabled",immediateField.prop("checked")?!0:!1);
        $("#field-schedule_unit").attr("disabled",immediateField.prop("checked"));
        $("#schedule-controls").css("display",immediateField.prop("checked")?"none":"block");
    });

    $("#casecategory_name").keyup(function(){
        var input=$(this);input.val().indexOf(",")!==-1&&input.val(input.val().replace(",",""))
    });

    $("#profile-form").submit(function(){
        var orgField=$("#org-tags");
        orgField.val() && orgField.tagsManager("pushTag",orgField.val());

        var awardField=$("#award-tags");
        awardField.val() && awardField.tagsManager("pushTag",awardField.val())
    });

    $('.floating-label .custom-select, .floating-label .form-control').floatinglabel();

    //Smooth Scrolling
    //if ($('.credentials a[href^="#"]').length>0) {
    $('.credentials a[href^="#"]').on('click', function (event) {
        var target = $(this.getAttribute('href'));
        if (target.length) {
            event.preventDefault();
            $('html, body').stop().animate({
                scrollTop: target.offset().top - 80
            }, 1000);
        }
    });
    //}

    $(".mask").inputmask({"mask": "(999) 999-9999"});

    //select2
    $('.select-simple').select2({
        theme: "material"
    });

    //clipboard copy
    var clipboard = new Clipboard('.copycode');

    clipboard.on('success', function(e) {
        console.log(e);
        alert('copied ' + e.text);
    });

    clipboard.on('error', function(e) {
        console.log(e);
    });

    // function formatState (state) {
    //     if (!state.id) {
    //         return state.text;
    //     }
    //     var baseUrl = "/user/pages/images/flags";
    //     var $state = $(
    //         '<small><img src="' + baseUrl + '/' + state.element.value.toLowerCase() + '.png" class="img-flag" /> ' + state.text + '</small>'
    //     );
    //     return $state;
    // };

    $('.select-multiple').select2({
        theme: "material",
        closeOnSelect: false,
        // tags: true,
        // tokenSeparators: [',', ' '],
        // createTag: function (params) {
        //     return {
        //         id: params.term,
        //         text: params.term,
        //         newOption: true
        //     }
        // },
        // templateResult: function (data) {
        //     var $result = $("<span></span>");
        //
        //     $result.text(data.text);
        //
        //     if (data.newOption) {
        //         $result.append(" <em>(new)</em>");
        //     }
        //
        //     return $result;
        // }
        // // templateResult: formatState
        // templateResult: function (data) {
        //     // We only really care if there is an element to pull classes from
        //     if (!data.element) {
        //         return data.text;
        //     }
        //
        //     var $element = $(data.element);
        //     console.log($element);
        //     var $wrapper = $('<spanish></spanish>');
        //     $wrapper.addClass($element[0].className);
        //
        //     $wrapper.text(data.text);
        //
        //     return $wrapper;
        // }
    });
    // // $(".modal select").select2({ dropdownParent: ".modal-content" });
    $('.select-modal-simple').select2({
        dropdownParent: $('#modalAddStageAction'),
        theme: "material"
    });

    $('.select-modal-simple-stage').select2({
        dropdownParent: $('#modalCaseStageChange'),
        theme: "material"
    });
    $('.select-modal-simple-postal').select2({
        dropdownParent: $('#modalAddPostal'),
        theme: "material"
    });
    // $('#expertise_expertExpertiseCategories').select2({
    //     dropdownParent: $('.modal'),
    //     tags: true,
    //     createTag: function (params) {
    //         var term = $.trim(params.term);
    //
    //         if (term === '') {
    //             return null;
    //         }
    //
    //         return {
    //             id: term,
    //             text: term,
    //             newTag: true // add additional parameters
    //         }
    //     },
    //     insertTag: function (data, tag) {
    //         // Insert the tag at the end of the results
    //         data.push(tag);
    //     }
    // });

    $(".select2-selection__arrow").addClass("material-icons").html("arrow_drop_down");
});
